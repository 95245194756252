import React from "react"
import { Link } from "gatsby"
import { Icon } from "react-icons-kit"
import { ic_web_outline } from "react-icons-kit/md/ic_web_outline"
import { code } from "react-icons-kit/entypo/code"
import { ic_saved_search } from "react-icons-kit/md/ic_saved_search"
import { stopwatch } from "react-icons-kit/entypo/stopwatch"
import { shield } from "react-icons-kit/fa/shield"
import { signal } from "react-icons-kit/fa/signal"
import { cloud } from "react-icons-kit/fa/cloud"
import { globe } from "react-icons-kit/fa/globe"
import { gears } from "react-icons-kit/fa/gears"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import IconCo2 from "../svg/CO2.svg"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": "https://netbistrot.com/en/",
        url: "https://netbistrot.com/en/",
        name: "NetBistrot",
        inLanguage: "en-US",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency"
        description="Great websites for small business."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="wrapper z-0">
        <PageHeader />
        <div className="main mx-2">
          <div className="section section-typo">
            <div className="mx-auto max-w-4xl">
              <div className="text-center ">
                <span className="text-4xl">
                  Great websites for small business.
                </span>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className=" col-span-8">
                  <p className="text-2xl">What we do:</p>
                  <div className="h-4" />
                  <p className="text-2xl pb-4">High performance websites</p>
                  <p className="text-2xl">Pretty fast page loading</p>
                  <p className="text-xl pl-6">improved user experience</p>
                  <p className="text-xl pl-6">improved user engagement</p>
                  <p className="text-xl pl-6">improved SEO ranking position</p>
                  <div className="h-4" />
                  <p className="text-2xl">Lower electrical consumption</p>
                  <p className="text-xl pl-6">environmental responsibility</p>
                  <div className="h-4" />
                  <p className="text-2xl">
                    Lower maintenance and ownership costs
                  </p>
                  <div className="h-4" />
                  <p className="text-2xl">Search engine optimization</p>
                  <p className="text-xl pl-6">improved ranking position</p>
                  <div className="h-4" />
                  <p className="text-2xl">Scalability</p>
                  <p className="text-xl pl-6">
                    ensure access even on high demand
                  </p>
                  <div className="h-8" />
                  <Link
                    className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                    to="/en/static-websites/"
                  >
                    How we do it?
                  </Link>
                </div>
              </div>
              <div className="h-16" />
              <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                <div className="text-center ">
                  <span className="text-2xl">
                    These are the concepts behind our work:
                  </span>
                </div>
              </div>
              <div className="h-10" />
              <div className="grid grid-cols-2 gap-x-10 gap-y-16 ">
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_web_outline} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-12">
                      <span className="text-2xl">custom design</span>
                      <br />
                      <span className="text-lg">
                        Your needs are not the same as others and a custom
                        design helps improve your online presence. We create a
                        custom web design for every project to fulfill every
                        technical or aesthetic requirement.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={code} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">custom code</span>
                      <br />
                      <span className="text-lg">
                        Your needs are not the same as others and a custom code
                        will solve any need that you may have.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">cloud hosting</span>
                      <br />
                      <span className="text-lg">
                        Cloud techniques are constantly evolving and we use the
                        state of the art to host your systems.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_saved_search} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">SEO best pratices</span>
                      <br />
                      <span className="text-lg">
                        We fulfill every requirement of the main search engines
                        and social networks so their algorithms will easily
                        locate your deployment and give them the best ranking.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <IconCo2
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "64px",
                          display: "inline",
                        }}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">
                        minimal CO<sub>2</sub> footprint
                      </span>
                      <br />
                      <span className="text-lg">
                        We design systems that use cloud resources only when
                        they are needed. This means that electrical consumption
                        is minimum.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon
                        size={64}
                        style={{ maxWidth: "86px" }}
                        icon={shield}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">security first</span>
                      <br />
                      <span className="text-lg">
                        Unless strictly necessary, we do not require a login.
                        When it is we use the latest techniques. Each base
                        system is only accessed with two factor authentication
                        with minimal permissions for the required task.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={stopwatch} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">fast performance</span>
                      <br />
                      <span className="text-lg">
                        We use a lot of features to make sure your system is as
                        fast as it can be and your users will notice this. As
                        search engines also value the speed this is of maximum
                        importance.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={signal} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">scalability</span>
                      <br />
                      <span className="text-lg">
                        As the systems we develop are serverless and uses cloud
                        resources only when they are needed they have much more
                        flexibility than a server based system to react to a
                        peak of requests or to slow down when there are few
                        users.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">cloud native</span>
                      <br />
                      <span className="text-lg">
                        We design systems that use the most recent cloud
                        features. We replace the servers by microservices that
                        run only when they are needed and CDN (content
                        distribution networks) that are always available.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={globe} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">CDN</span>
                      <br />
                      <span className="text-lg">
                        Our web systems are deployed in a dozen of locations
                        around the globe to ensure that your users will receive
                        the contents from a near CDN with the minimum delay
                        wherever they are.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={gears} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">API</span>
                      <br />
                      <span className="text-lg">
                        We develop APIs to meet the needs of our systems and to
                        expand its ability through third party resources like
                        payment systems or courrier companies.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-20" />
        </div>
        <Footer lang="en" />
      </div>
    </>
  )
}

export default IndexEN
